#Templates {
    padding-top: 3.5rem;
}

.bm-highlights {
    background: #e1ebff;
}

.team p {
    font-size: 1rem;
}