.navbar {
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background: #f4f3ff;
}

.logo {
    text-decoration: none; 
    font-family: "Titan One", sans-serif;
    font-size: 2rem;
    color: #2DBB5D;
}

.nav-item a {
    color: #333;
}

.nav-item a:hover {
    text-decoration: underline;
}